
import RcTableViewModel from "../../../../../shared/table/RcTableViewModel";

import ListViewUtils from "./ListViewUtils";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import InvoiceListFilter from '@/domain/model/invoice/InvoiceListFilter.js';
import InvoiceMap from '@/domain/model/invoice/InvoiceMap.js';


export default class ListViewModel extends RcTableViewModel {
    
  constructor(panel) {
    super(panel);
    this._companyId = ContentUtils.baseDecode(panel.$route.params.operatorId);
    this._userId = null;
    this._total = 0;
    this._error = null;
    this._itemList = [];
    this._pages = [];
    this.SU = StringUtils;
    this.CU = ContentUtils;
  }

  start() {
    super.start();
  }

  company() {
    return this.domain().companys().findById(this._companyId);
  }
  
  isAdmin() {
    return this.panel().isAdmin;
  }

  withValue(valueData) {
    super.withValue(valueData);
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems() {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading Items");
    
    var filter = new InvoiceListFilter();
    filter
      .withPagination(this.pageSize(query), query["page"] ? (query["page"] - 1) : 0)
      .withCompany(this._companyId)
      .done();
    
    var context = {};
    context["model"] = this;
      
    var event = this.domain().events().invoice().list(filter);
    event.send(this.loadedItemsListener, context);
  }

  loadedItemsListener(responseEvent, context) {
    var model = context.model;
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var values = payload.valuesFor(InvoiceMap.MODEL_NAME);
      model._invoiceMap = new InvoiceMap(this.domain, values);
      model._itemList = model._invoiceMap.sort();
    }
    var query = model.panel().$route.query;
    model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query))
    model
      .withStateContentLoaded()
      .clearAndBuildTableData()
      .done();
    model.panel().showDialogIfRequired();
  }

  invoices() {
    return this._itemList;
  }

  totalItems() {
    return this._total;
  }

  pages() {
    return this._pages;
  }

  error() {
    return this._error;
  }

}