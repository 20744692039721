import RcTableViewUtils from "../../../../../shared/table/RcTableViewUtils";
import BoxDisplayRow from "@/portals/shared/cell/box/BoxDisplayRow";
import RowDisplayRow from "@/portals/shared/cell/row/RowDisplayRow";
import StringDisplayRow from "@/portals/shared/cell//string/StringDisplayRow";
// import StringUpdateRow from "@/portals/shared/cell/string/StringUpdateRow.js";
import StringListUpdateRow from "@/portals/shared/cell/string/list/StringListUpdateRow.js";
import LabelValueDisplayRow from "@/portals/shared/cell//label/LabelValueDisplayRow";
import TitleDisplayRow from "@/portals/shared/cell/title/TitleDisplayRow";
import ButtonDisplayRow from "@/portals/shared/cell/button/ButtonDisplayRow";
import SpacerDisplayRow from "@/portals/shared/cell/spacer/SpacerDisplayRow";
// import StringUtils from '@/utils/StringUtils.js';
import StatusDisplayRow from "@/portals/shared/cell/status/StatusDisplayRow.js";
//import DateUpdateRow from "@/portals/shared/cell/date/DateUpdateRow.js";

import MC from "@/domain/session/MC.js";
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

export default class ListViewUtils extends RcTableViewUtils {

  constructor(model, tableData) {
    super(model, tableData);
    this.MC = new MC();
    this.pages = [];
    this.Strings = new StringUtils();
  }

  start() {
    if (this.model().hasError()) {
      this.renderError();  
    }

    if (this.model().isStatePageLoading()) {
      super.start();
      return;
    }

    this.renderHeader(this.model().totalItems());

    if (this.model().isStateContentLoading()) {
      super.start();
      return;
    }

    if (this.model().isStateContentLoaded()) {
      this.renderBody();
    }

    this.renderFooter(this.model().totalItems());
  }

  renderBody() {
    var box = new BoxDisplayRow()
      .withBorder(true);
    box
      .withChild(new RowDisplayRow()
        .withBackgroundTitle()
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Invoice #"))
        .withChild(new TitleDisplayRow().withAlign("centre").withWidth("3").withTitle("Issued On"))
        .withChild(new TitleDisplayRow().withWidth("3").withTitle("Total"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Due"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Invoice"))
        .withChild(new TitleDisplayRow().withWidth("2").withTitle("Paid"))
      );

    var even = true;
    var list = this.model().invoices();
    for (var index = 0; index < list.length; index++) {
      var invoice = list[index];
      if (invoice) {
        //
      }
      var dueDate = invoice.dueDate();
      var now = this.domain().time().now();

      var paidValue = "notpaid";
      if (invoice.paid()) {
        paidValue = "paid";
      } else {
        if (now.time() > dueDate.time()) {
          paidValue = "pastdue"
        } else if (dueDate.minus(1).time() < now.time()) {
          paidValue = "notpaid1day";
        } else if (dueDate.minue(5).time() < now.time()) {
          paidValue = "notpaid1week";
        } else {
          paidValue = "notpaid";
        }
      }
      var statusRow = new StatusDisplayRow().withWidth("3").withValue(paidValue);
      if (invoice.paid()) {
        statusRow.withHint(invoice.paidDate().displayAs_YYYY_MM_DD());
      }
      var invoiceRow = new RowDisplayRow()
      invoiceRow
        .withChild(new StringDisplayRow().withWidth("3").withValue(invoice.number()))
        .withChild(new StringDisplayRow().withWidth("3").withValue(invoice.createdDateDisplay()))
        .withChild(new StringDisplayRow().withWidth("2").withValue(invoice.totalWithTaxes().toFixed(2)))
        .withChild(new StringDisplayRow().withWidth("3").withValue(invoice.dueDateDisplay()))
        .withChild(new ButtonDisplayRow().withWidth("1").withName("PDF").withField("Pdf").withId(invoice.id()))
        .withChild(statusRow)
        ;
      box.withChild(new BoxDisplayRow().withEven(even).withChild(invoiceRow));
      even = !even;
    }
  
    this.addBody(box);
  }

  renderFooter(totalItems) {
    var query = this.model().panel().$route.query;
    this.pages = this.model().pages();
    this.add(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("7"))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(this.pages))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
    );
  }

  renderHeader(totalItems) {
    var query = this.model().panel().$route.query;
    this.add(
      new RowDisplayRow()
      .withChild(
        new BoxDisplayRow()
        .withBorder(true)
        .withWidth("15")
        .withChild(new RowDisplayRow()
          .withChild(new LabelValueDisplayRow().withWidth("1").withLabel(""))
          .withChild(new SpacerDisplayRow().withWidth("12"))
          .withChild(new ButtonDisplayRow().withWidth("2").withName("Refresh").withField("Refresh"))
        )
        
        .withChild(new RowDisplayRow()
          .withChild(new BoxDisplayRow()
            .withWidth("15")
            .withBorder(true)
            .withChild(new RowDisplayRow()
              .withChild(new LabelValueDisplayRow().withWidth("4").withLabelWidth("5").withLabel("Showing").withValue(this.createPageInfo(totalItems, query)))
              .withChild(new StringDisplayRow().withWidth("4"))
              .withChild(new StringListUpdateRow().withWidth("3")
                                                  .withName("Items/Page")
                                                  .withField("PageSize")
                                                  .withShowLabel(false)
                                                  .withDefaultValue(this.model().pageSize(query))
                                                  .withOptions(ContentUtils.PAGE_SIZES()))
              .withChild(new ButtonDisplayRow().withWidth("1").withName("<").withField("PreviousPage"))
              .withChild(new StringListUpdateRow().withWidth("2")
                                                  .withShowLabel(false)
                                                  .withName("Jump to Page")
                                                  .withField("PageIndex")
                                                  .withDefaultValue(query["page"] ? query["page"] : "1")
                                                  .withOptions(ContentUtils.PAGE_INDEXES(totalItems, this.model().pageSize(query))))
              .withChild(new ButtonDisplayRow().withWidth("1").withName(">").withField("NextPage"))
            )
          )
        )
      )
    );

    // if (!user.isNew()) {
    //   var box = new BoxDisplayRow().withBorder(true);
    //   var title = StringUtils.format("Timesheets for {0}", [user.fullName()]);
    //   box.withChild(new RowDisplayRow()
    //       .withBackgroundTitle()
    //       .withChild(new TitleDisplayRow().withWidth("15").withTitle(title))
    //     );
    //   this.add(box);
    // }
  }

  makeAndModel(vehicle) {
    var value = "";
    var makeSet = false;
    if (StringUtils.isNotEmpty(vehicle.make())) {
      value = vehicle.make();
      makeSet = true;
    }
    if (StringUtils.isNotEmpty(vehicle.model())) {
      if (makeSet) {
        value = value + " / " + vehicle.model();
      } else {
        value = "<Not Set>" + "/ " + vehicle.model();
      }
    } else {
      if (makeSet) {
        value = value + " / " + "<Not Set>"
      }
    }
    return value;
  }
  
  categoryName(vehicle) {
    var category = vehicle.category().find();
    var list = ContentUtils.CATEGORIES();
    for (var i = 0; i < list.length; i++) {
      var item = list[i];
      if (item['@rid'] == category.id()) {
        return item['name'];
      }
    }
    return category.name();
  }
}